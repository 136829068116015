import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../components/layout/Layout';

const ShippingDetails = () => {
  return (
    <Layout title={"easyjackets-Shipping details"}>
    <Container className="my-5">
      <Row>
        <Col>
          <h2>Refund & Shipping Policy</h2>

          {/* Customs/Duty */}
          <h4>Customs/Duty</h4>
          <p>
            All the customs/duty charges are the responsibility of our store EASYJACKETS.
          </p>

          {/* Shipping Cost */}
          <h4>Shipping Cost</h4>
          <p>
            We charge the shipping cost according to the country’s tax system. We provide free shipment for bulk orders, the origin of the order may be anywhere in the World.
          </p>

          {/* Refund Policy */}
          <h4>Refund Policy</h4>
          <p>
            EASYJACKETS has a strict refund policy. If you want to change a product, you must follow the instructions below.
          </p>

          {/* Limited Time */}
          <h6>Limited Time</h6>
          <p>We have a 10-day refund/return policy. Refunds or exchanges are allowed within this period.</p>

          {/* Shipping Cost for Returns */}
          <h6>Return Shipping Cost</h6>
          <p>The cost of shipping the returned item will be borne by the customer.</p>

          {/* Defective or Incorrect Products */}
          <h6>Defective or Incorrect Products</h6>
          <p>
            If you believe the product is defective or if we made errors in the design, the company will re-examine the product upon return and notify you with further instructions via email.
          </p>

          {/* Return/Refund Conditions */}
          <h4>Return/Refund Conditions</h4>
          <ul>
            <li>All plain/stock jackets require prior authorization and are subject to a 15%-25% deduction. They must be returned within 10 days of delivery.</li>
            <li>Decorated jackets with issues must be reported within 10 days of delivery.</li>
            <li>Refund/exchange requests must have a valid reason.</li>
            <li>The product must be in its original packaging, unworn, and unaltered.</li>
            <li>Products bought by EASYJACKETS will be returned or exchanged by the manufacturer.</li>
            <li>Shipping costs for returns are the customer’s responsibility.</li>
            <li>Exchanges for color/size are allowed if the product price remains the same. For higher-priced exchanges, the customer must pay the difference.</li>
          </ul>

          {/* Order Cancellation */}
          <h4>Order Cancellation</h4>
          <p>
            If a customer decides not to accept the product upon delivery or provides inadequate delivery information, the company may cancel the order and retain the product, with a 15%-25% deduction from the bill.
          </p>
          <p>
            You must inform the company via email if you placed a wrong order or wish to change/cancel an order within 24 hours of purchase. Cancellations/changes made after 24 hours will not be accepted.
          </p>
          <p>
            For authorization, please submit a request through our Contact Us page and return the product within 10 days if approved.
          </p>

          {/* Return Authorization */}
          <h4>Return Authorization</h4>
          <p>
            If you qualify for a return or exchange, please request a return authorization by writing to us at: <a href="mailto:help@easyjackets.com">help@easyjackets.com</a>.
          </p>
          <p>
            The returned jacket will be credited to the original payment method, and the new jacket(s) will be charged to the same payment method. Shipping charges for returned items are non-refundable.
          </p>

          {/* No Refund After 10 Days */}
          <h4>No Refund After 10 Days</h4>
          <p>
            There are NO REFUND POLICIES after 10 days. Custom-made garments designed using our DESIGN LAB are non-returnable/non-exchangeable. Personalized items are specially made for you and cannot be returned, exchanged, or canceled.
          </p>

          <p>
            Please consider these conditions carefully before making a purchase with us.
          </p>
        </Col>
      </Row>
    </Container>
    </Layout>
  );
};

export default ShippingDetails;
