import { useEffect } from 'react'
import './App.css'
import Layout from "./components/layout/Layout.js";
import { Routes, Route , useLocation } from "react-router-dom";
import HomePage from "./pages/HomePage.js";
import About from "./pages/About.js";
import Contact from "./pages/Contact.js";
import Policy from "./pages/Policy.js";
import PageNotFound from "./pages/PageNotFound.js";
import Register from "./pages/Auth/Register.js";
import { ToastContainer } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/Auth/Login.js";
import Dashboard from "./pages/user/Dashboard.js";
import PrivateRoute from "./components/Routes/Private.js";
import ForgotPassword from "./pages/Auth/ForgotPassword.js";
import AdminDashboard from "./pages/Admin/AdminDashboard.js";
import AdminRoute from "./components/Routes/AdminRoute.js";
import CreateCategory from "./pages/Admin/CreateCategory.js";
import CreateProduct from "./pages/Admin/CreateProduct.js";
import Users from "./pages/Admin/Users.js";
import Orders from "./pages/user/Orders.js";
import Profile from "./pages/user/Profile.js";
import Products from "./pages/Admin/Products.js";
import UpdateProduct from "./pages/Admin/UpdateProduct.js";
import Search from "./pages/Search.js";
import ProductDetails from "./pages/ProductDetails.js";
import Categories from "./pages/Categories.js";
import CategoryProduct from "./pages/CategoryProduct.js";
import CartPage from "./pages/CartPage.js";
import AdminOrders from "./pages/Admin/AdminOrders";
import OurWork from "./pages/OurWork.js";
import AllProducts from "./pages/AllProducts.js";
import ViewDetails from "./pages/ViewDetails.js";
import CheckoutForm from "./pages/CheckoutForm.js";
import PaymentCancel from "./pages/PaymentCancel.js";
import PaymentSucess from "./pages/PaymentSucess.js";
import { Design } from './pages/Design.js';
import { BulkOrders } from './pages/BulkOrders.js';
import { VarcityJackets } from './pages/VarcityJackets.js';
import { SizeChart } from './pages/SizeChart.js';
import { Patches } from './pages/Patches.js';
import Faq from './pages/Faq.js';
import { Blogs } from './pages/Blogs.js';
import { DesignCustomJacket } from './pages/DesignCustomJacket.js';
import OrderPage, { Order } from './pages/Order.js';
import PrivacyPolicy from './pages/PrivacyPolicy.js';
import ShippingDetails from './pages/ShippingDetails.js';

// import webpackConfig from "./webpack.config.js";
const ScrollToTop = () => {
  // Extracts pathname property(key) from an object
  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}

function App() {
  return (
    <>
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/product/:slug" element={<ProductDetails />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/category/:slug" element={<CategoryProduct />} />
        <Route path="/search" element={<Search />} />
        <Route path="/AllProducts" element={<AllProducts />} />
        <Route path="/Design/:id" element={<ViewDetails />} />
        <Route path="/checkout-form" element={<CheckoutForm/>} />
        <Route path="/success" element={<PaymentSucess/>}/>
        <Route path="/cancel" element={<PaymentCancel/>} />
        <Route path="/dashboard" element={<PrivateRoute />}>
          <Route path="user" element={<Dashboard />} />
          <Route path="user/orders" element={<Orders />} />
          <Route path="user/profile" element={<Profile />} />
        </Route>
        <Route path="/our-work" element={<OurWork />} />
        <Route path="/dashboard" element={<AdminRoute />}>
          <Route path="admin" element={<AdminDashboard />} />
          <Route path="admin/create-category" element={<CreateCategory />} />
          <Route path="admin/create-product/:id" element={<CreateProduct />} />
          <Route path="admin/product/:slug" element={<UpdateProduct />} />
          <Route path="admin/products" element={<Products />} />
          <Route path="admin/users" element={<Users />} />
          <Route path="admin/orders" element={<AdminOrders />} />
        </Route>

        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/policy" element={<Policy />} />
        <Route path='/design' element={<Design/>}/>
        <Route path='/bulkorder' element={<BulkOrders/>}/>
        <Route path='/varsity' element={<VarcityJackets/>}/>
        <Route path='/sizechart' element={<SizeChart/>}/>
        <Route path='/patches' element={<Patches/>}/>
        <Route path='/faq' element={<Faq/>}/>
        <Route path='/blog' element={<Blogs/>}/>
        <Route path='/checkout' element={<CheckoutForm/>}/>
        <Route path='/design-custom-jacket' element={<DesignCustomJacket/>}/>
        <Route path='/order/:id' element={<OrderPage/>}/>
        <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
        <Route path="/shipping" element={<ShippingDetails/>}/>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
