import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Layout from '../components/layout/Layout';

const PrivacyPolicy = () => {
  return (
    <Layout title={"easyjackets-Privacy policy"}>
    <Container className="my-5">
      <Row>
        <Col>
          <h2>Privacy Policy</h2>
          <p>Last updated: December 19, 2022</p>
          <p>
            This Privacy Policy describes Our policies and procedures on the collection, use, 
            and disclosure of Your information when You use the Service and tells You about 
            Your privacy rights and how the law protects You. By using the Service, You agree 
            to the collection and use of information in accordance with this Privacy Policy.
          </p>

          {/* Interpretation and Definitions */}
          <h4>Interpretation and Definitions</h4>
          <h6>Interpretation</h6>
          <p>
            The words of which the initial letter is capitalized have meanings defined 
            under the following conditions. The following definitions shall have the 
            same meaning regardless of whether they appear in singular or in plural.
          </p>
          <h6>Definitions</h6>
          <p><strong>Account:</strong> A unique account created for You to access our Service.</p>
          <p><strong>Company:</strong> Refers to Easy Jackets, Sialkot, Pakistan.</p>
          <p><strong>Cookies:</strong> Small files stored on Your device by a website.</p>
          <p><strong>Country:</strong> Pakistan</p>
          <p><strong>Device:</strong> Any device that can access the Service.</p>
          <p><strong>Personal Data:</strong> Information relating to an identifiable individual.</p>
          <p><strong>Service:</strong> Refers to the Website.</p>
          <p><strong>Service Provider:</strong> Third-party companies or individuals employed to facilitate the Service.</p>
          <p><strong>Usage Data:</strong> Data collected automatically when using the Service.</p>
          <p><strong>Website:</strong> Easy Jackets, accessible from <a href="https://www.easyjackets.com/">https://www.easyjackets.com/</a></p>
          <p><strong>You:</strong> The individual accessing or using the Service.</p>

          {/* Collecting and Using Your Personal Data */}
          <h4>Collecting and Using Your Personal Data</h4>
          <h6>Types of Data Collected</h6>
          <p><strong>Personal Data:</strong> We may ask for information such as your email, name, phone number, and address.</p>
          <p><strong>Usage Data:</strong> Collected automatically, including IP address, browser type, pages visited, and more.</p>
          <h6>Tracking Technologies and Cookies</h6>
          <p>
            We use Cookies and similar tracking technologies to track activity on our Service 
            and store certain information. You may refuse Cookies, but it may affect your usage of the Service.
          </p>

          {/* Use of Your Personal Data */}
          <h4>Use of Your Personal Data</h4>
          <p>Your Personal Data may be used for purposes such as:</p>
          <ul>
            <li>Providing and maintaining our Service</li>
            <li>Managing Your account</li>
            <li>Contacting You</li>
            <li>Providing special offers and information on related goods</li>
            <li>Handling Your requests</li>
            <li>Business transfers, analytics, and more</li>
          </ul>

          {/* Retention of Your Personal Data */}
          <h4>Retention of Your Personal Data</h4>
          <p>
            We retain Personal Data only as long as necessary to fulfill purposes outlined 
            in this policy, comply with legal obligations, resolve disputes, and enforce policies.
          </p>

          {/* Transfer of Your Personal Data */}
          <h4>Transfer of Your Personal Data</h4>
          <p>
            Your information may be transferred to computers located outside of your jurisdiction. 
            We ensure that adequate data protection measures are in place.
          </p>

          {/* Delete Your Personal Data */}
          <h4>Delete Your Personal Data</h4>
          <p>
            You have the right to delete Personal Data collected about you. You may update, amend, or delete your information by managing account settings or contacting us.
          </p>

          {/* Disclosure of Your Personal Data */}
          <h4>Disclosure of Your Personal Data</h4>
          <p>We may disclose your Personal Data under certain circumstances:</p>
          <ul>
            <li><strong>Business Transactions:</strong> For mergers, acquisitions, or asset sales.</li>
            <li><strong>Law enforcement:</strong> In response to valid legal requests.</li>
            <li><strong>Legal Requirements:</strong> For compliance, protection, and safety purposes.</li>
          </ul>

          {/* Security of Your Personal Data */}
          <h4>Security of Your Personal Data</h4>
          <p>
            We use commercially acceptable means to protect your Personal Data, 
            though we cannot guarantee absolute security.
          </p>

          {/* Children’s Privacy */}
          <h4>Children’s Privacy</h4>
          <p>
            Our Service does not address anyone under 13. We do not knowingly collect data from children 
            under 13 without parental consent.
          </p>

          {/* Links to Other Websites */}
          <h4>Links to Other Websites</h4>
          <p>
            Our Service may contain links to other websites. We are not responsible 
            for the content, privacy policies, or practices of these third-party sites.
          </p>

          {/* Changes to this Privacy Policy */}
          <h4>Changes to this Privacy Policy</h4>
          <p>
            We may update this Privacy Policy periodically. You are advised to review 
            this policy regularly for any changes.
          </p>

          {/* Contact Us */}
          <h4>Contact Us</h4>
          <p>If you have any questions, you can contact us:</p>
          <ul>
            <li>By email: Info@easyjackets.com</li>
            <li>By visiting our website: <a href="https://www.easyjackets.com/">easyjackets.com</a></li>
          </ul>
        </Col>
      </Row>
    </Container>
    </Layout>
  );
};

export default PrivacyPolicy;
